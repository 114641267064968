
                @import '@style/mixins';
                @import '@skillbox/front-box/dist/Functional/UiVariables/style';
              
.geography {
  overflow-x: hidden;
}

.geography__list {
  @include ulres;

  display: flex;

  &:nth-of-type(2n) {
    direction: rtl;
  }
}

.geography__item {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 500;
  line-height: 1;

  @include media(lg) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &::before {
    display: block;
    width: 8px;
    height: 8px;
    margin-right: 24px;
    margin-left: 24px;
    border-radius: 50%;
    content: '';
    background-color: var(--stroke-main-color);

    @include media(lg) {
      width: 12px;
      height: 12px;
      margin-right: 54px;
      margin-left: 54px;
    }
  }
}

// Top row
.geography__list--top {
  .geography__item {
    font-size: 36px;

    @include media(lg) {
      font-size: 58px;
    }
  }
}

// Middle row
.geography__list--mid {
  .geography__item {
    font-size: 30px;
    border-top: 2px solid var(--stroke-main-color);
    border-bottom: 2px solid var(--stroke-main-color);

    @include media(lg) {
      font-size: 50px;
    }

    &::before {
      margin-right: 32px;
      margin-left: 32px;

      @include media(lg) {
        margin-right: 48px;
        margin-left: 48px;
      }
    }
  }
}

// Bottom row
.geography__list--bottom {
  .geography__item {
    font-size: 26px;

    @include media(lg) {
      font-size: 40px;
    }

    &::before {
      @include media(lg) {
        margin-right: 44px;
        margin-left: 44px;
      }
    }
  }
}
